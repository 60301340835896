<template>
	<div class="">
		<div v-if="loading">
			<div class="center text-center p-4">
				<span class="spinner-border spinner-border-sm"></span>
				<span class="ml-2">Loading...</span>
			</div>
		</div>
		<div v-else>
			<div class="main-content">
				<div class="header bg-white border-bottom">
					<div class="container-fluid">
						<div class="header-body border-0">
							<div class="row align-items-end">
								<div class="col">
									<h6 class="header-pretitle">Category Overview</h6>
									<h1 class="header-title">
										{{ categoryData ? `${categoryData.name}` : '' }}
									</h1>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="container-fluid">
					<router-link
						to="/configuration/customer-app/trip-rating-settings"
						class="btn btn-light mb-4 btn-sm"
						>
						Go back
					</router-link>
					<div class="row">
						<div class="col-12 col-xl-6">
							<div class="card">
								<div class="card-header justify-content-between">
									<h4 class="card-header-title">Category Information</h4>
								</div>
								<div class="card-body p-2">
									<div class="list-group list-group-flush mb-4">
										<div class="list-group-item">
											<div class="row align-items-center">
												<div class="col">
													<span class="text-muted text-uppercase"
														>Category</span
														>
												</div>
												<div class="col-auto">
													<span class="">{{ `${categoryData.name}` }}</span>
												</div>
											</div>
										</div>
										<div class="list-group-item">
											<div class="row align-items-center">
												<div class="col-md-6">
													<span class="text-muted text-uppercase"
														>Issue Types
													</span>
												</div>
												<div class="col-md-6 text-right">
													<span
														v-for="issue in categoryIssues"
														:key="issue.reference"
														class="badge badge-pill badge-secondary mx-1"
														>{{ issue.name }}</span
														>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<!-- UPDATE CATEGORY SECTION -->
						<div class="col-12 col-xl-6">
							<div class="card">
								<div class="card-header">
									<div>
										<h4 class="card-header-title">Update Category</h4>
									</div>
								</div>
								<div class="card-body">
									<div
										class="alert alert-danger alert-dismissible fade show"
										role="alert"
										v-if="errorMessage"
										>
										{{ errorMessage }}
									</div>
									<div>
										<form @submit.prevent="updateCategory">
											<div class="row mt-4">
												<div class="col-12 col-md-12">
													<div class="form-group">
														<label class="form-label"> Category name </label>
														<input
															v-model.trim="category.name"
															required
															type="text"
															class="form-control"
															/>
													</div>
												</div>
											</div>
											<div class="row mt-4">
												<div class="col-12 col-md-12">
													<div class="form-group">
														<label class="form-label">
															Issue type
															<span class="font-italic"
																>(Minimum of two issues)</span
																>
														</label>
														<div class="input-group mb-3">
															<input
																type="text"
																class="form-control"
																placeholder="Enter issue type"
																aria-label="Enter issue type"
																aria-describedby="button-addon"
																v-model.trim="issueType"
																/>
															<div class="input-group-append">
																<button
																	class="btn btn-primary"
																	:disabled="!issueType"
																	type="button"
																	id="button-addon"
																	@click.prevent="addIssueToList"
																	>
																	Add
																</button>
															</div>
														</div>
													</div>

													<div
														class="list-group list-unstyled"
														v-if="category.issues"
														>
														<div
															v-for="(item, index) in category.issues"
															:key="item.index"
															class="px-0 py-1 d-flex justify-content-between"
															>
															{{ item.name || item }}
															<i
																class="fe fe-x text-danger pointer"
																@click="deleteIssueFromList(item, index)"
																></i>
														</div>
													</div>
												</div>
											</div>

											<div class="row"></div>

											<button
												type="submit"
												class="btn btn-primary mt-4"
												:disabled="
													processing || !category.name || !category.issues
												"
												>
												{{
													processing
														? 'Updating category...'
														: `Update Category`
												}}
											</button>
										</form>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
  props: {
    categoryId: {
      required: true,
      type: [Number, String]
    }
  },
  components: {},
  data () {
    return {
      categoryData: null,
      category: { name: '', issues: [] },
      loading: false,
      errorLoading: false,
      categoryIssues: null,
      errorMessage: '',
      issueType: '',
      processing: false,
      existingIssuesForDeletion: []
    }
  },
  created () {
    this.init()
  },
  computed: {},
  methods: {
    async init () {
      this.loading = true
      this.errorLoading = false

      this.fetchCategory()
        .then(() => {
          this.fetchCategoryIssues()
        })
        .catch(() => (this.errorLoading = true))
        .finally(() => (this.loading = false))
    },
    fetchCategory () {
      return this.axios
        .get(`/rating/categories/${this.categoryId}`)
        .then((res) => {
          this.categoryData = res.data.data
          this.category.name = res.data.data.name
        })
    },
    fetchCategoryIssues () {
      this.loadingCategoryIssues = true
      this.errorLoadingCategoryIssues = false

      this.axios
        .get(`/rating/options/${this.categoryId}`)
        .then((res) => {
          if (res.data.data) {
            this.categoryIssues = res.data.data
            this.category.issues = res.data.data
          } else {
            this.category.issues = []
          }
        })
        .catch(() => (this.errorLoadingCategoryIssues = true))
        .finally(() => (this.loadingCategoryIssues = false))
    },
    addIssueToList () {
      this.category.issues.unshift(this.issueType)
      this.issueType = ''
    },
    deleteIssueFromList (item, indexOfItem) {
      if (item.reference) {
        this.existingIssuesForDeletion.push(item)
      }
      this.category.issues = this.category.issues.filter(
        (item, index) => index !== indexOfItem
      )
    },
    async updateCategory () {
      this.processing = true
      this.errorLoading = false
      this.errorMessage = ''

      const categoryPayload = {
        settings_id: this.categoryData.settings_id,
        name: this.category.name
      }

      const response = await this.axios.patch(
        `/rating/categories/${this.categoryId}`,
        categoryPayload
      )
      const updatedCategory = response.data.data

      const issuesForDeletionRequests = this.existingIssuesForDeletion.map(
        (issue) => this.axios.delete(`/rating/options/${issue.reference}`)
      )

      const issueTypesRequests = this.category.issues
        .filter((item) => !item.reference)
        .map((issue) =>
          this.axios.post('/rating/options', {
            name: issue,
            settings_id: updatedCategory.settings_id,
            category_id: updatedCategory.reference
          })
        )

      const combinedRequests = [
        ...issuesForDeletionRequests,
        ...issueTypesRequests
      ]

      Promise.all(combinedRequests)
        .then(() => {
          this.$swal({
            icon: 'success',
            title: 'Category Updated',
            text: 'Category has been updated successfully',
            showCloseButton: true
          })
          this.init()
        })
        .catch((e) => {
          this.$swal({
            icon: 'error',
            title: 'Error Occured',
            text: 'An error occured, please review',
            showCloseButton: true
          })

          let msg = e.toString()
          if (e && e.response && e.response.data && e.response.data.message) {
            msg = e.response.data.message
          }

          this.errorMessage = msg

          this.errorLoading = true
          this.processing = false
        })
        .finally(() => (this.processing = false))
    }
  }
}
</script>

<style scoped>
</style>
